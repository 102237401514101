import { useContext } from "react"
import { Link } from "react-router-dom";
import { AuthContext } from "../context/auth"
import { useBack } from "../hooks/back";

export default function Dashboard() {
  const { userDetails } = useContext(AuthContext);
  useBack(false);

  return <div className="dashboard">
    Hi, {userDetails.name}
    <Link to="/competition" className="btn lg">Go to Judging</Link>
  </div>
}