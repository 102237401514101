import { forwardRef, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useMouseHovered, useDebounce } from 'react-use';
import VideoProgress from './VideoProgress';

const Video = forwardRef(({ url, taps, startTapTime, endTapTime, onProgressChange = () => { } }, ref) => {
  const [progress, setProgress] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const videoPlayer = ref?.current?.player;
  const videoEl = ref?.current?.getInternalPlayer();
  const indicatorRef = useRef();


  const handleProggres = (e) => {
    setProgress(e);
    onProgressChange(e);
  };

  const togglePlay = () => {
    if (!videoPlayer || !videoEl) return;
    if (videoPlayer?.isPlaying) {
      return videoEl.pause();
    }
    videoEl.play();
  };


  const cool = useMouseHovered(indicatorRef, { bound: true, whenHovered: true });

  useEffect(() => {
    setIsHovering(true);
  }, [cool]);

  useDebounce(
    () => setIsHovering(false),
    2000,
    [cool]
  );

  return <>
    <div ref={indicatorRef} className={`review__video-status ${videoPlayer?.isPlaying && 'playing'} ${isHovering && 'is-hovering'}`} onClick={togglePlay}>
      <ReactPlayer
        onClick={togglePlay}
        url={url}
        ref={ref}
        onProgress={handleProggres}
        className="review__video"
        width='100%'
        progressInterval={30}
        height='100%' />

    </div>
    <VideoProgress
      taps={taps}
      startTapTime={startTapTime}
      endTapTime={endTapTime}
      progress={progress}
      videoRef={ref}
    />
  </>
});

export default Video;