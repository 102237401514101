import axios from "../axios";

export async function checkIsAuthenticated() {
  const { data } = await axios.get('/auth/me');
  return data;
};

export async function authLogin(credentials) {
  const { data } = await axios.post('/auth/login', credentials);
  const userToken = `Bearer ${data.access_token}`;
  axios.defaults.headers.common['Authorization'] = userToken;
  localStorage.setItem('user_token', userToken);
  return true;
}

export async function authLogout() {
  localStorage.removeItem('user_token');
  axios.defaults.headers.common['Authorization'] = false;
}
