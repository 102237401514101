import moment from "moment"
import { useHistory } from "react-router";

export default function CompetitionItem({ details, ordinal }) {
  const history = useHistory();
  const today = moment();
  const isPassed = (date) => moment(date).isBefore(today);

  // TODO: Should have competition status
  const readyToJudge = isPassed(details.qualifications_to) || isPassed(details.finale_to);

  const enterCompetition = () => {
    history.push('/competition/' + details.id)
  }

  return <button onClick={enterCompetition} className="competition__item">
    <span className="competition__ordinal">{ordinal}.</span>
    <div className="competition__name-wrapper">
      <h5 className="competition__name" title={details.name}>{details.name}</h5>
    </div>
    {readyToJudge && <span className="competition__review-button btn link yellow">Judge Review</span>}
    {/* <span className="competition-status">{details.name}</span> */}
  </button>
};