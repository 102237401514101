import { Link, useLocation } from "react-router-dom";

export default function AthleteSingle({ athlete, ordinal, limitLine }) {
  const { user } = athlete;
  const location = useLocation();


  function displayStatusOrButton() {
    if (athlete.reviewed) return <div className="athlete__done"><button className="btn link">Edit</button></div>
    if (athlete.disqualificated) return <div className="athlete__disqualificated"></div>
    return <Link to={`/review?back=${location.pathname}`} className="btn outline review__button">Review</Link>
  }

  return <li className={`athlete__single ${limitLine ? 'limit-reached' : ''}`}>
    <span className="athlete__ordinal">{ordinal}.</span>
    <div className="athlete__wrapper">
      <div className="athlete__avatar"></div>
      <div className="athlete__info">
        <h4 className="athlete__name">{user.name}</h4>
      </div>
    </div>
    {displayStatusOrButton()}
  </li>
}