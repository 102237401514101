import React, { useState, useEffect } from 'react'
import { checkIsAuthenticated, authLogin, authLogout } from '../services/auth'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import axios from '../axios';
import 'react-toastify/dist/ReactToastify.css';

export const AuthContext = React.createContext({})

export default function Auth({ children }) {
  const history = useHistory();

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [userDetails, setUserDetails] = useState(null);

  function displayError(message) {
    const defaultMessage = 'Something went wrong, please try again';
    toast.error(message || defaultMessage, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }


  useEffect(() => {
    if (axios.defaults.headers.common['Authorization']) {
      checkAuth()
    } else {
      setIsLoading(false)
    }
  }, []);

  const checkAuth = async () => checkIsAuthenticated()
    .then(setUserDetails)
    .then(() => setIsAuthenticated(true))
    .catch(() => setIsAuthenticated(false))
    .then(() => setIsLoading(false))

  const login = credentials => authLogin(credentials)
    .then(checkAuth)
    .then(() => history.replace('/'))
    .catch(error => {
      displayError(error?.response?.data?.error)
      setIsAuthenticated(false)
    });

  const resetAuth = () => {
    authLogout();
    setUserDetails(null);
    setIsAuthenticated(false);
  }

  const logout = () => {
    history.replace('/auth');
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout, userDetails, resetAuth }}>
      {children}
      <ToastContainer />
    </AuthContext.Provider>
  )
}