import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth from './context/auth';
import Store from './context/store';
import App from './App';
import { Provider as BusProvider } from 'react-bus'

// TODO: 8:42 - 11:44
// TODO: 8:00 - 12:00

// TODO: Add custom text input
// TODO: Add custom confirm
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <BusProvider>
        <Auth>
          <Store>
            <App />
          </Store>
        </Auth>
      </BusProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);