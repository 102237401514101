import listIcon from '../../img/list.svg';
import closeIcon from '../../img/close.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReviewLog from './ReviewLog';
import ReviewRepControls from './RepControls';
import { ToastContainer, toast } from 'react-toastify';
import { compareNumbers, msToTime } from '../../utils';
import { useKeyPressEvent } from 'react-use';
import { SUBMIT_DISQUALIFICATE, SUBMIT_DONE } from './SubmitControls';
import { useListener } from 'react-bus'
import Video from '../video/Video';
import { uuid } from 'uuidv4';


const toastOptions = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

const dummyTaps = [
  { id: 2, time: 2500 },
  { id: 3, time: 3000 },
  { id: 4, time: 4000 },
  { id: 5, time: 5000 },
  { id: 6, time: 6000 },
  { id: 7, time: 7000 },
  { id: 8, time: 8000 },
  { id: 9, time: 9000 },
  { id: 10, time: 10500 },
];

let wasPlaying = false;
export default function ReviewReps({ startTapTime = 1000, endTapTime = 12000, taps = dummyTaps }) {
  const [isLogActive, setIsLogActive] = useState(false);

  const videoRef = useRef();
  const [videoEl, setVideoEl] = useState(null);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [videoProgress, setVideoProgress] = useState(null);

  useEffect(() => {
    if (videoRef.current) {
      setVideoEl(videoRef.current.getInternalPlayer());
      setVideoPlayer(videoRef.current.player);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef.current]);

  const toggleActivityLog = () => {
    setIsLogActive(!isLogActive)
    if (!isLogActive) {
      wasPlaying = videoPlayer.isPlaying;
      return videoEl.pause();
    }
    if (wasPlaying) {
      videoEl.play();
    }
  };

  function addToast(time, log) {
    if (!isLogActive) {
      const method = log.add ? 'success' : 'error';
      const fullMessage = `${msToTime(time)} - ${log.message}`;
      toast[method](fullMessage, toastOptions);
    }
  };

  const getActiveTapId = (time) => {
    let tapID;
    extendedTaps.forEach(tap => {
      if ((tap.time + startTapTime) < time) {
        tapID = tap.id;
      }
    });
    return tapID;
  }

  const [logs, setLog] = useState({});
  const sortedLogs = useMemo(() => Object.entries(logs)
    .sort(([keyA, valuesA], [keyB, valueB]) => compareNumbers(valuesA.time, valueB.time))
    .map(([key, value]) => ({ tapID: key, ...value })), [logs]);

  const onLogAdd = (newLog) => {
    if (newLog.time >= endTapTime) return
    if ((newLog.time - startTapTime) < 0) return;

    let { time, add } = newLog;
    let tapID = getActiveTapId(time);

    function showError(message) {
      videoEl.pause();
      return toast.error(message, {
        ...toastOptions,
        position: 'bottom-left',
        autoClose: 3000,
        hideProgressBar: false,
      })
    }

    if (!tapID && !add) {
      return showError('No tap to be commented');
    }

    if (add) {
      tapID = uuid();
    } else if (logs[tapID]?.add) { // REMOVE ADD REP
      const filteredLogs = { ...logs };
      delete filteredLogs[tapID];
      return setLog(filteredLogs);
    }

    newLog.time = time - startTapTime;

    if (Object.values(logs).some(e => e.time === newLog.time)) {
      return showError('You added rep here already');
    }

    setLog({ ...logs, [tapID]: newLog })
    addToast(time, newLog);
  };

  const removeLog = (tapID) => {
    const newState = { ...logs };
    if (newState[tapID]) delete newState[tapID];
    setLog(newState)
  }
  const editLog = (newLog) => setLog({ ...logs, ...newLog })

  const timeSeek = (time) => {
    videoEl.currentTime = (time + startTapTime) / 1000;
  }

  useKeyPressEvent(' ', () => {
    const isVideoFocused = document.activeElement.tagName === 'VIDEO';
    if (isVideoFocused || isLogActive) return;
    if (videoPlayer.isPlaying) return videoEl.pause();
    videoEl.play();
  });

  useListener(SUBMIT_DISQUALIFICATE, () => { console.log(SUBMIT_DISQUALIFICATE) })
  useListener(SUBMIT_DONE, () => {
    const dataForAPI = Object.keys(logs).reduce((acc, curr) => {
      const reviewLog = { ...logs[curr] };
      delete reviewLog.add;
      delete reviewLog.edit;
      return { ...acc, [curr]: reviewLog };
    }, {});
    console.log(dataForAPI)
  })


  const [extendedTaps, setExtendedTaps] = useState(taps);

  useEffect(() => {
    const repsAdded = Object.entries(logs)
      .filter(([key, value]) => value.add) // Only logs that is "Add Rep"
      .map(([key, value]) => ({ id: key, ...value, review: true })); // Prepare object as reps are

    let newList = taps
      .concat(repsAdded) //Merge these two arrays
      .sort((a, b) => a.time - b.time); // Sort by time descending


    const currentProgress = videoProgress?.played * 100;
    const duration = Math.round(videoRef.current.getDuration() * 1000);

    newList = newList
      .map((tap) => {
        return { ...tap, percent: (tap.time + startTapTime) * 100 / duration }
      })
      .map((tap, index, array) => {
        let active = false;
        let negative = false;
        if (videoProgress?.playedSeconds * 1000 < endTapTime && tap.percent < currentProgress) {
          active = !array.find(e => e.percent > tap.percent && e.percent < currentProgress)
        }
        if (Object.entries(logs).some(([key, value]) => key.toString() === tap.id.toString() && !value.add)) {
          negative = true;
        }
        return { ...tap, active, negative };
      });

    setExtendedTaps(newList);
  }, [logs, taps, videoProgress, startTapTime, endTapTime])

  return <div className="review__page">
    <div className="review__video-wrapper">
      <Video url="https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4"
        ref={videoRef}
        taps={extendedTaps}
        startTapTime={startTapTime}
        endTapTime={endTapTime}
        onProgressChange={setVideoProgress}
      />
      <div className="review__video-overlay">
        <div className="review__video-overlay__top">
          <div className="athlete__wrapper">
            <div className="athlete__avatar"></div>
            <h4 className="athlete__name">N. Ivanovic</h4>
          </div>
          <dl className="review__details">
            <dt>For Time - TC 15 min</dt>

            <dt>Reps</dt>
            <dd>100</dd>

            <dt>Time</dt>
            <dd>TC</dd>

            <dt>Position</dt>
            <dd>3</dd>
          </dl>
        </div>
        <div className="review__video-overlay__bottom">
          <button className={`review__activity__toggler ${isLogActive ? 'active' : ''}`} onClick={toggleActivityLog}>
            <img src={listIcon} alt="" />
            <img src={closeIcon} alt="" />
          </button>
        </div>
        {isLogActive &&
          <div className="review__activity">
            {sortedLogs.map(log => {
              return <ReviewLog
                key={log.tapID}
                log={log}
                onTimeClick={timeSeek}
                onRemove={removeLog}
                onEdit={editLog}
              />
            })}
          </div>
        }
      </div>
    </div>
    <ReviewRepControls
      onLogAdd={onLogAdd}
      videoRef={videoRef} />

    <ToastContainer />
  </div>
}