export default function TapProgress({ taps, startTime, endTapTime, dur }) {
  return <ul className="video-progress__taps">
    {taps
      .map(tap => {
        return <li
          style={{ left: tap.percent + '%' }}
          className={`tap ${tap.active && 'active'} ${tap.review && 'review'} ${tap.negative && 'negative'}`}
          key={tap.id}
        >
        </li>
      })}

    <li className="flag-start" style={{ left: startTime * 100 / dur + '%' }}></li>
    <li className="flag-end" style={{ left: endTapTime * 100 / dur + '%' }}></li>
  </ul>
}