import AthleteSingle from "./AthleteSingle";

export default function AthleteList({ data }) {
  const limitLine = 5;

  return <ul className="athlete__list">
    {data.map((athlete, index) => <AthleteSingle
      athlete={athlete}
      key={athlete.id}
      ordinal={index + 1}
      limitLine={index + 1 === limitLine}
    />)}
  </ul>
}