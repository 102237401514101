import axios from "../axios";

export async function getAllCompetitions() {
  const { data } = await axios.get('/competition');
  return data;
};

export async function getSingleCompetition(id) {
  const { data } = await axios.get(`/competition/${id}/details`);
  return data;
};