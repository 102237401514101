import { useEffect, useState } from "react";
import CompetitionList from "../components/competition/List";
import Loading from "../components/Loading";
import { useBack } from "../hooks/back"
import { getAllCompetitions } from "../services/competition";

export default function CompetitionLanding() {
  useBack('/', 'Dashboard');
  const [competitions, setCompetitions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      const response = await getAllCompetitions();
      setCompetitions(response.data);
      setIsLoading(false);
    }
    getData();
  }, []);

  if (isLoading) return <Loading />

  return <div className="competition-page">
    <CompetitionList data={competitions} />
  </div>
}