import { useEffect, useRef, useState } from "react";
import { useTimeoutFn } from "react-use";
import { Range } from 'react-range';
import TapProgress from "./TapProgress";
import { VideoProgressZoomControl } from "./VideoProgressZoomControl";

export default function VideoProgress({ taps, videoRef, progress, startTapTime, endTapTime }) {
  const [dur, setDur] = useState(0);
  const progressWrapper = useRef(null);

  const [zoom, setZoom] = useState(1);
  const [zoomEnable, setZoomEnable] = useState(false);

  useTimeoutFn(() => {
    setDur(Math.round(videoRef.current.getDuration() * 1000))
  }, 100);

  const seekVideo = ([percent]) => {
    const videoEl = videoRef.current.getInternalPlayer();
    if (videoRef.current.player.isPlaying) {
      videoEl.pause();
    }
    videoRef.current.seekTo(percent / 100)
  }

  const [progressValue, setProgressValue] = useState([0]);

  useEffect(() => {
    setProgressValue([progress.played * 100]);
  }, [progress.played]);

  const width = zoom * 100;
  let wrapperStyle = {
    width: width + '%',
    transform: `translateX(${100 / (zoom * 2)}%) translateX(${'-' + (progress.played * 100) + "%"})`
  }

  if (!zoomEnable) wrapperStyle = {};

  return <>
    <VideoProgressZoomControl onUpdate={setZoom} open={zoomEnable} toggle={() => setZoomEnable(!zoomEnable)} />

    <div className="video-progress" style={{ overflow: 'hidden' }}>
      <div className="video-progress__bar-wrapper" ref={progressWrapper} style={wrapperStyle}>
        <Range
          step={0.1}
          min={0}
          max={100}
          values={progressValue}
          onChange={seekVideo}
          onFinalChange={seekVideo}
          onMouseDown={(e) => console.log("STARTED", e)}
          renderTrack={({ props, children }) => {
            return <div {...props}
              style={props.style}
              className="video-progress__bar"
            >
              {children}
              {taps && taps.length ?
                <TapProgress
                  startTime={startTapTime}
                  endTapTime={endTapTime}
                  taps={taps}
                  currentProgress={progressValue[0]}
                  dur={dur} /> : null}
            </div>
          }}
          renderThumb={({ props }) => (
            <div
              className="video-progress__pointer"
              {...props}
              style={{ ...props.style, pointerEvents: zoomEnable ? 'none' : 'all' }}
            />
          )}
        />
      </div>
    </div>
  </>
}