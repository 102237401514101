import React, { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';

export const StoreContext = React.createContext({})

export default function Store({ children }) {
  // BACK STATE
  const [backState, setBackState] = useState(false);

  // CURRENT DIVISION
  const [currentDivision, setCurrentDivision] = useState(null);
  const resetCurrentDivision = () => setCurrentDivision(null);

  // DIVISION ATHLETES
  const [divisionAthletes, setDivisionAthletes] = useState([]);
  const resetDivisionAthletes = () => setDivisionAthletes([]);

  return (
    <StoreContext.Provider value={{
      // BACK STATE
      backState,
      setBackState,

      // CURRENT DIVISION
      currentDivision,
      setCurrentDivision,
      resetCurrentDivision,

      // DIVISION ATHLETES
      divisionAthletes,
      setDivisionAthletes,
      resetDivisionAthletes
    }}>
      {children}
    </StoreContext.Provider>
  )
}