import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'


import Header from './components/Header'
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Competition from './pages/Competition';
import Page404 from './pages/404';
import CompetitionDetails from './pages/CompetitionDetails';
import Review from './pages/Review';
import Wod from './pages/Wod';
import RegisterSuccess from './pages/RegisterSuccess';
import { useLocation } from 'react-use';

export default function App() {
  const location = useLocation();
  let appClassName = '';

  if (location.pathname === '/registration-success' || location.pathname === '/no-match') {
    appClassName = 'site-message--wrapper';
  }
  return (
    <div id="app" className={appClassName}>
      <Header />
      <main className="main">
        <Switch>
          <Route exact path="/auth" component={Login} />
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute path="/competition/:id" component={CompetitionDetails} />
          <PrivateRoute exact path="/competition" component={Competition} />
          <PrivateRoute path="/wod/:competition_id/:wod_id" component={Wod} />
          <PrivateRoute exact path="/review" component={Review} />
          <Route path="/registration-success" component={RegisterSuccess} name="register" />
          <Route path="/no-match" component={Page404} />
          <Route component={Page404} />
        </Switch>
      </main>
    </div>
  )
}