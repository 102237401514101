import { useEffect, useState } from "react";
import { useKeyPressEvent } from "react-use";
import searchIcon from '../../img/search.svg'

export function VideoProgressZoomControl({
  open,
  toggle,
  minZoom = 2,
  onUpdate,
  maxZoom = 10,
  shortKey = "z"
}) {

  const [zoom, setZoom] = useState(minZoom);

  const increase = () => zoom < maxZoom && setZoom(zoom + 1);
  const decrease = () => zoom > minZoom && setZoom(zoom - 1);

  useKeyPressEvent(shortKey, toggle);
  useKeyPressEvent('+', increase);
  useKeyPressEvent('-', decrease);

  useEffect(() => {
    onUpdate(zoom);
  }, [zoom, onUpdate]);

  return <div className="video-progress__zoom__control">
    <button data-char={shortKey} className={`video-progress__zoom__toggle btn sm ${open && 'active'}`} onClick={toggle}><img src={searchIcon} width="20px" height="20px" alt="" /></button>
    {
      open &&
      <div className="video-progress__zoom__actions">
        <button className="btn sm yellow" onClick={decrease} disabled={zoom <= 2}>-</button>
        <output>{zoom}x</output>
        <button className="btn sm yellow" onClick={increase} disabled={zoom >= maxZoom} >+</button>
      </div>
    }
  </div>
};