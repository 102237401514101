import { useEffect } from "react";
import { useStore } from "./store";

export const useBack = (path, label = "") => {
  const { setBackState } = useStore()
  useEffect(() => {
    if (!path) return setBackState(false);
    setBackState({
      path,
      label
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};