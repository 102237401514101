import { useRef, useState } from 'react';
import { useKeyPressEvent } from 'react-use';
import ReviewWeightControls from './WeightControls';
import { useListener } from 'react-bus';
import { SUBMIT_DISQUALIFICATE, SUBMIT_DONE } from './SubmitControls';
import { compareNumbers } from '../../utils';
import Video from '../video/Video';

export default function ReviewWeight() {
  const videoRef = useRef();

  const [weightList] = useState([50, 55, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150]);
  const [selectedWeight, setSelectedWeight] = useState(null);

  const selectWeight = (weight) => {
    if (weight === selectedWeight) return setSelectedWeight(null)
    setSelectedWeight(weight);
  }

  useKeyPressEvent(' ', (e) => {
    if (document.activeElement.tagName === 'VIDEO') return;
    const videoEl = videoRef.current.getInternalPlayer();
    if (videoRef.current.player.isPlaying) return videoEl.pause();
    videoEl.play();
  });

  useListener(SUBMIT_DISQUALIFICATE, () => { console.log(SUBMIT_DISQUALIFICATE) })
  useListener(SUBMIT_DONE, () => { console.log(selectedWeight) })

  return <div className="review__page">
    <div className="review__video-wrapper">
      <Video url="https://www.w3schools.com/html/movie.mp4"
        ref={videoRef}
      />
      <div className="review__video-overlay">
        <div className="review__video-overlay__top">
          <div className="athlete__wrapper">
            <div className="athlete__avatar"></div>
            <h4 className="athlete__name">N. Ivanovic</h4>
          </div>
          <dl className="review__details">
            <dt>For Time - TC 15 min</dt>

            <dt>Reps</dt>
            <dd>100</dd>

            <dt>Time</dt>
            <dd>TC</dd>

            <dt>Position</dt>
            <dd>3</dd>
          </dl>
        </div>
      </div>
    </div>

    <ReviewWeightControls
      weightList={weightList.sort(compareNumbers)}
      selectedWeight={selectedWeight}
      selectWeight={selectWeight}
    />
  </div>
}