export function msToTime(duration) {
  var milliseconds = parseInt((duration % 1000) / 100) * 10
    , seconds = parseInt((duration / 1000) % 60)
    , minutes = parseInt((duration / (1000 * 60)) % 60)
    , hours, starthours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  if (starthours > 0) {
    hours += ":";
  } else {
    hours = "";
  }

  return hours + minutes + ":" + seconds + "." + milliseconds;
}

export function createRipple(button) {

  const circle = document.createElement("span");
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${button.clientWidth / 2 - radius}px`;
  circle.style.top = `${button.clientHeight / 2 - radius}px`;
  circle.classList.add("ripple");

  const ripple = button.getElementsByClassName("ripple")[0];

  if (ripple) {
    ripple.remove();
  }

  button.appendChild(circle);
}


export function compareNumbers(a, b) { return parseInt(b) - parseInt(a) }