import Loading from "../Loading";
import WodSingle from "./WodSingle";

export default function WodList({ data, loading, competition_id }) {
  if (loading) return <Loading />
  return <ul className="wod__list">
    {data.map(wod => <WodSingle
      details={wod}
      key={wod.wod_id}
      competition_id={competition_id}
    />)}
  </ul>
}