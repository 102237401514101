import closeIcon from '../../img/close.svg';
import { msToTime } from "../../utils";

export default function ReviewLog({
  onTimeClick = () => { },
  onEdit = () => { },
  onRemove = () => { },
  log }) {

  const displayTime = msToTime(log.time);

  const onClickEdit = () => {
    const newMessage = prompt('Your message', log.message);
    if (newMessage !== null && newMessage.length) {
      const { tapID, ...logObj } = log;
      onEdit({ [tapID]: { ...logObj, message: newMessage } })
    }
  }

  return <div className={`review__activity__log ${log.edit && 'message'} ${log.add ? '' : 'negative'}`}>
    <div className="review__activity__message-container">
      <button className="seek" onClick={() => onTimeClick(log.time)}>{displayTime}</button>
      <p>{log.message}</p>
    </div>
    <div className="review__activity__actions">
      {log.edit &&
        <button className="btn link edit__message" onClick={onClickEdit}>Edit</button>
      }
      <button className="log__remover" onClick={() => onRemove(log.tapID)}>
        <img src={closeIcon} alt="" />
      </button>
    </div>
  </div>
}