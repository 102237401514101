import { useContext, useEffect } from "react";
import { AuthContext } from "../context/auth";
import useQuery from "../hooks/query";

export default function RegisterSuccess() {
  const { resetAuth } = useContext(AuthContext);
  const query = useQuery();

  const name = query.get('name')

  useEffect(() => {
    resetAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="site-message">
    <h1>Congratulations
      {name && <>, <span>{name}</span></>}
    </h1>
    <p>You have just verified your e-mail address successfully.
      Open the App and start competing!</p>
  </div>
}