import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AthleteList from '../components/athlete/AthleteList';
import Loading from '../components/Loading';
import { useBack } from '../hooks/back';
import { getAthletes } from '../services/athletes';

export default function Wod() {
  const { wod_id, competition_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [athletes, setAthletes] = useState([]);

  useBack('/competition/' + competition_id, 'Competition')

  const fetchAthletes = async (competition_id, wod_id) => {
    setIsLoading();
    try {
      const { data } = await getAthletes(competition_id, wod_id);
      setAthletes(data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchAthletes(competition_id, wod_id);
  }, [wod_id, competition_id]);

  if (isLoading) return <Loading />

  return <AthleteList data={athletes} />
}