import moment from "moment";
import { Link } from "react-router-dom";

export default function WodSingle({ details, competition_id }) {
  const { start, name, wod_id } = details;

  const isDone = moment(start).isBefore();

  return <li>
    <Link to={`/wod/${competition_id}/${wod_id}`} className="wod__single">
      <dl>
        <dt>{name}</dt>
        <dd>{moment(start).format('DD MMM YYYY - hh:mm')}</dd>
      </dl>
      {isDone ? <span className="btn link green">Finished</span> : <span className="btn link yellow">Starts {moment(start).fromNow()}</span>}
    </Link>
  </li>
}