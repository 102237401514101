import { useBus } from 'react-bus'

export const SUBMIT_DISQUALIFICATE = '@submit/DISQUALIFICATE'
export const SUBMIT_DONE = '@submit/DONE'

export default function ReviewSubmitControls() {
  const bus = useBus();

  return <div className="review__submit-controls">
    <button className="btn link red" onClick={() => bus.emit(SUBMIT_DISQUALIFICATE)}>Disqualification</button>
    <button className="btn" onClick={() => bus.emit(SUBMIT_DONE)}>Done</button>
  </div>
}