
import { useRef, useState } from 'react';
import { useKeyPressEvent } from 'react-use';
import { createRipple } from '../../utils';

export default function ReviewRepControls({
  onLogAdd = () => { },
  videoRef,
  controlDelay = 300
}) {
  const [isDisabled, setIsDisabled] = useState(false);
  const getVideoCurrentTime = () => Math.round(videoRef.current.getCurrentTime() * 1000);
  const noRepRef = useRef();
  const noRepComRef = useRef();
  const addRepRef = useRef();

  const onNoRepWithComment = () => {
    const video = videoRef.current.getInternalPlayer();
    const isPlaying = !video.paused;
    video.pause();
    const message = prompt('Your message', '');
    if (message !== null && message.length) {
      if (isPlaying) {
        video.play();
      }
      return { message, edit: true };
    }
  };
  const onAddRep = () => ({ message: 'Add Rep', add: true });
  const onNoRep = () => ({ message: 'No Rep', edit: true });

  const handleClick = (callback, button) => {
    if (isDisabled) return;
    setIsDisabled(true);
    createRipple(button);
    const log = callback();
    if (!log) return setIsDisabled(false);
    const time = getVideoCurrentTime();
    onLogAdd({ ...log, time });
    setTimeout(() => setIsDisabled(false), controlDelay);
  };

  useKeyPressEvent('n', () => handleClick(onNoRep, noRepRef.current));
  useKeyPressEvent('a', () => handleClick(onAddRep, addRepRef.current));
  useKeyPressEvent('c', () => handleClick(onNoRepWithComment, noRepComRef.current));



  return <div className="review__controls">
    <button ref={noRepRef} disabled={isDisabled} onClick={(e) => handleClick(onNoRep, e.currentTarget)}>No Rep (N)</button>
    <button ref={noRepComRef} disabled={isDisabled} onClick={(e) => handleClick(onNoRepWithComment, e.currentTarget)}>No Rep with Comment (C)</button>
    <button ref={addRepRef} disabled={isDisabled} onClick={(e) => handleClick(onAddRep, e.currentTarget)}>Add Rep (A)</button>
  </div>

}