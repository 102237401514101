import { useState } from "react";
import ReviewReps from "../components/review/ReviewReps";
import { useBack } from "../hooks/back";
import ReviewMaxWeight from "../components/review/ReviewWeight";
import useQuery from "../hooks/query";

const REVIEW_TYPE_REPS = "REVIEW_TYPE_REPS";
const REVIEW_TYPE_WEIGHT = "REVIEW_TYPE_WEIGHT";



export default function Review() {
  const query = useQuery();
  useBack(query.get('back'), 'Athlete List')
  const [reviewType] = useState(
    REVIEW_TYPE_REPS
    // REVIEW_TYPE_WEIGHT
  );

  function renderReview() {
    if (reviewType === REVIEW_TYPE_REPS) return <ReviewReps />;
    if (reviewType === REVIEW_TYPE_WEIGHT) return <ReviewMaxWeight />;
  }

  return renderReview();
}