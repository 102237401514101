

export default function ReviewWeightControls({
  weightList,
  selectedWeight,
  selectWeight
}) {
  return <div className="review__controls">
    {weightList.map(weight =>
      <button
        key={weight}
        className={selectedWeight === weight ? 'selected' : ''}
        onClick={() => selectWeight(weight)}>
        {weight} kg
      </button>)}
  </div>
}