import { useContext, useEffect } from "react"
import { AuthContext } from "../context/auth"
import { useBack } from "../hooks/back";
import { useInput } from "../hooks/input";


export default function Login() {
  useBack(false);
  const { login, resetAuth } = useContext(AuthContext);

  const { value: email, bind: bindEmail } = useInput('');
  const { value: password, bind: bindPassword } = useInput('', 'password');

  useEffect(() => {
    resetAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submit(e) {
    e.preventDefault();
    if (!email || !password) return;
    login({ email, password });
  }

  return <form onSubmit={submit} className="login">
    <input {...bindEmail} placeholder="Email" />
    <input {...bindPassword} placeholder="Password" />
    <button className="btn block lg">Submit</button>
  </form>
}