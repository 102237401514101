import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DivisionList from "../components/division/DivisionList";
import Loading from "../components/Loading";
import WodList from "../components/wod/WodList";
import { useBack } from "../hooks/back";
import { useStore } from "../hooks/store"
import { getSingleCompetition } from "../services/competition";
import { getWodsForCompetition } from "../services/wods";


export default function CompetitionDetails() {
  useBack('/competition', 'Competitions')
  const history = useHistory();
  const [competition, setCompetition] = useState(null);

  const { currentDivision, setCurrentDivision } = useStore();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [wods, setWods] = useState([]);
  const [wodLoading, setWodLoading] = useState(false);

  useEffect(() => {
    setWodLoading(true);
    setTimeout(() => setWodLoading(false), 500);
  }, [currentDivision]);

  const fetchWodsForCompetition = async (competition_id) => {
    try {
      const { data } = await getWodsForCompetition(competition_id);
      setWods(data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchCompetition = async () => {
    try {
      const data = await getSingleCompetition(id);
      setCompetition(data);
      await fetchWodsForCompetition(data.id);
      if (data.competition_division.length) {
        setCurrentDivision(data.competition_division[0].id);
      }
      setIsLoading(false);
    } catch (e) {
      history.replace('/competition');
    }
  }

  useEffect(() => {
    fetchCompetition();

    return () => {
      setCompetition(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredWods = wods.filter(e => e.competition_division_id === currentDivision);

  if (isLoading) return <Loading />

  if (!competition || !competition.competition_division.length) return <h2 className="page__status">Sorry no division assosieted</h2>

  return <div className="competition__details">
    <DivisionList data={competition.competition_division} />

    <WodList data={filteredWods} loading={wodLoading} competition_id={competition.id} />
  </div>
}