import { useContext } from "react"
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../context/auth"
import { useStore } from "../hooks/store";
import logo from '../img/logo.svg';
import arrow from '../img/arrow.svg';
import ReviewSubmitControls from "./review/SubmitControls";

export default function Header() {
  const { logout, isAuthenticated } = useContext(AuthContext);
  const { backState } = useStore();
  const location = useLocation();

  return <header className="header">
    {backState && <Link to={backState.path} className="back__button">
      <img src={arrow} alt="" width="20px" height="20px" />
      <span>{backState.label}</span>
    </Link>}
    <img src={logo} alt="Circle21 Logo" className="logo" />
    <div id="header_portal">
      {location.pathname === '/review' &&
        <ReviewSubmitControls />
      }
    </div>
    {isAuthenticated &&
      <button className="btn outline logout" onClick={logout}>Logout</button>
    }
  </header>
}